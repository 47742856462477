
  
  import { defineComponent, ref, onMounted, computed, reactive } from "vue";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions } from '@/store/enums/StoreEnums' 
  import { useStore } from "vuex";
  import { initImgPath } from "@/core/helpers/imgpath";
  import router from "@/router";
  import ApiService from "@/core/services/ApiService";
  export default defineComponent({
    name: "news-listing",
    components: {
      Datatable,
    },
    setup() {
      const store = useStore()
      const checkedCustomers = ref([]);
      const tableHeader = ref([
        // {
        //   key: "checkbox",
        //   sortable: false,
        // },
        {
          name: "Sarlavha",
          key: "name",
          sortable: true,
        },
        {
          name: "Yo'nalish",
          key: "category",
          sortable: true,
        },
        {
          name: "Turkum",
          key: "subcategory",
          sortable: true,
        },
        {
          name: "Ko'rilgan",
          key: "views",
          sortable: true,
        },
        {
          name: "Tipi",
          key: "type",
          sortable: true,
        },
        {
          name: "Dolzarb",
          key: "breaking",
          sortable: true,
        },
        {
          name: "Slider",
          key: "slider",
          sortable: true,
        },
        {
          name: "Ko'rsatilgan",
          key: "publishpermission",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
        {
          name: "",
          key: "actions",
        },
      ]);
  

      const dataTable = computed(()=>{
            return store.getters.alNews
        })


      const count = computed(()=>{
            return store.getters.getCount
        })
      

      const edit = ref<boolean>(false)
      const loading = ref<boolean>(false)

      const itemId = ref<string>("")

      const addRouter = ()=>{
        router.push('news/add-news')
      }

      const editItem = (item: string)=>{
        router.push('news/news-edit/'+item)
        
      }

      const changeStatus = async(item: string, status: number)=>{
        await store.dispatch(Actions.CHANGE_NEWS_STATUS, {item, status});
      }

      const user = computed(()=>{
        return store.getters.currentUser
      })

      const changePublish = async(item: string, val: boolean, authorId: string, title: string)=>{
        console.log(authorId);
        
        await ApiService.get('user/getone/'+authorId).then(res=>{
          const data = {title:title, notification_token:  res.data.notification_token}
          store.dispatch(Actions.CHANGE_NEWS_PUBLISH, {item, val, data});
        })
      }

      const changeBreaking = async(item: string, status: boolean)=>{
        await store.dispatch(Actions.CHANGE_NEWS_BREAKING, {item, status});
      }

      const changeSlider = async(item: string, status: boolean)=>{
        await store.dispatch(Actions.CHANGE_NEWS_SLIDER, {item, status});
      }



      const changeRole = async(item: string, role: string)=>{
        await store.dispatch(Actions.CHANGE_USER_ROLE, {item: item, role:{role:role}});
      }
  
      const deleteFewCustomers = () => {
        checkedCustomers.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCustomers.value.length = 0;
      };
  
      const deleteCustomer = async(id:string) => {
        await store.dispatch(Actions.DEL_TAG, id);
      };

      const categories = computed(()=>{
        return store.getters.alCategories
      })

      const subcategories = computed(()=>{
        return store.getters.alSubategories
    })


      const currentPage = ref(1)
      onMounted(async() => { 
        setCurrentPageBreadcrumbs("Yangiliklar", []);
        loading.value = true
        await store.dispatch(Actions.ALL_CATEGORIES, {params:{limit: 0}});
        await store.dispatch(Actions.ALL_SUBCATEGORIES, {params:{limit: 0}});
        await store.dispatch(Actions.ALL_NEWS, {params: {limit: 10, next: currentPage.value, search: filter.search, category: filter.category, subcategory: filter.subcat}});
        loading.value = false
      });

      const perPage = async(val:number)=>{
        await store.dispatch(Actions.ALL_NEWS, {params:{limit: val}});
      }

      
      const changeCurrent = async(current: number)=>{ 
        currentPage.value = current
        await store.dispatch(Actions.ALL_NEWS, {params:{next: currentPage.value, search: filter.search, category: filter.category, subcategory: filter.subcat}});
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 1);
      }
      const checkValue = (val, slug1)=>{
        if(val){
          if(slug1 == "category"){
            return val[slug1].slug
          }
          return val[slug1]
        }
        return ''
      }

      const checkSubcat =(row)=>{
        if(row.subcategory === null){
          return ''
        }
        return row.subcategory.title
      }

      const checkType =(row)=>{
        if(row.type === null){
          return ''
        }
        return row.type.title
      }


  
      const filter = reactive({
        search:'',
        category: '',
        subcat: ''
      });

      const selectCategory =()=>{
        store.dispatch(Actions.ALL_NEWS, {params:{category: filter.category}})
        store.dispatch(Actions.ALL_SUBCATEGORIES, {params:{category: filter.category, limit: 0}});
        filter.subcat = ''
      }

      const searchItems = async() => {
        await store.dispatch(Actions.ALL_NEWS, {params:{search: filter.search, category: filter.category, subcategory: filter.subcat}});
      };


      const clear = async()=>{
        filter.search = '';
        filter.category = '';
        filter.subcat = ''

        await store.dispatch(Actions.ALL_NEWS, {params: {limit: 10, next: currentPage.value}});
      }
  
      // const searchingFunc = (obj, value): boolean => {
      //   // for (let key in obj) {
      //   //   if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
      //   //     if (obj[key].indexOf(value) != -1) {
      //   //       return true;
      //   //     }
      //   //   }
      //   // }
      //   // return false;
      // };
      

      return {
        tableHeader,
        deleteCustomer,
        filter,
        searchItems,
        checkedCustomers,
        deleteFewCustomers,
        dataTable, edit, editItem, itemId, perPage, changeStatus, initImgPath, count, changeRole, changeSlider,
         addRouter, changeCurrent, changePublish, changeBreaking, checkValue, currentPage, subcategories, categories, clear, loading, checkSubcat, checkType, selectCategory
      };
    },
  });
  